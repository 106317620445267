<template>
  <!--客户回传配置-->
  <div style="padding: 20px;">
    <div>
      <div class="title">
        {{ $route.meta.title }}
      </div>

      <!--搜索条件-->
      <el-card shadow="never" style="margin-top: 20px;">
        <div class="card">
          <div class="card_t">
            <div class="card_t_icon"></div>
            <div class="card_t_text">
              筛选条件
            </div>
          </div>
          <div class="card_b">
            <!-- 搜索表单 -->
            <el-form
              :model="where"
              label-width="90px"
              class="ele-form-search"
              @submit.native.prevent>
              <el-row :gutter="15">
                <el-col :lg="6" :md="12">
                  <el-form-item label="关键词:">
                    <el-input
                      clearable
                      v-model="where.keyword"
                      placeholder="请输入关键字"/>
                  </el-form-item>
                </el-col>
                <el-col :lg="4" :md="12">
                  <div class="ele-form-actions">
                    <el-button
                      type="primary"
                      class="ele-btn-icon"
                      @click="QueryList">查询
                    </el-button>
                    <el-button @click="reset">清空</el-button>
                  </div>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
      </el-card>

      <!--数据表格-->
      <el-card shadow="never">
        <div class="card">
          <div class="Datalist">
            <div class="Datalist_l">
              <div class="Datalist_icon"></div>
              <div class="Datalist_text">
                数据列表
              </div>
              <div class="Datalist-botton" style="margin-left: 20px">

              </div>
            </div>
            <div>
              <el-button type="warning" @click="addsheng(null)">新增</el-button>
            </div>
          </div>

          <div class="card_b">
            <!-- 数据表格 -->
            <my-table
              ref="myTable"
              :columns="columns"
              :tableData="List"
              :total="total"
              :headerCellStyle="headerCellStyle"
              :rowClassName="rowClassName"
              :customsFromWhere="where"
              :loading="loading"
            >

              <template v-slot:ak_account="{ scope }">
                <div style="margin-bottom: 10px;">
                  <el-tag type="success">测试：{{scope.row.test_ak_account}}</el-tag>
                </div>
               <div>
                 <el-tag type="danger">正式：{{scope.row.formal_ak_account}}</el-tag>
               </div>
              </template>

              <template v-slot:ak_secret="{ scope }">
                <div style="margin-bottom: 10px;">
                  <el-tag type="success">测试：{{scope.row.test_ak_secret}}</el-tag>
                </div>
                <div>
                  <el-tag type="danger">正式：{{scope.row.formal_ak_secret}}</el-tag>
                </div>
              </template>

              <template v-slot:api_url="{ scope }">
                <div style="margin-bottom: 10px;">
                  <el-tag type="success">测试：{{scope.row.test_api_url}}</el-tag>
                </div>
                <div>
                  <el-tag type="danger">正式：{{scope.row.formal_api_url}}</el-tag>
                </div>
              </template>


              <template v-slot:status="{ scope }">
                <span v-if="scope.row.status == 0">启用</span>
                <span v-else>禁用</span>
              </template>

              <template v-slot:operationSlot="{ scope }">
                <el-link
                  :underline="false"
                  type="warning"
                  @click="addsheng(scope.row)"
                  style="margin-right: 10px"
                >编辑</el-link
                >
              </template>
            </my-table>
          </div>
        </div>
      </el-card>
    </div>

    <el-dialog :title="title" :visible.sync="dialogVisible" width="30%" :close-on-click-modal="false" top="5vh" >
      <!-- <span>这是一段信息</span> -->
      <div v-loading="loading1">
        <el-form ref="form" :model="form" label-width="100px" label-position="left">
          <el-form-item label="选择服务商:">
            <el-select
              filterable
              clearable
              v-model="form.firm_id"
              @change="selectfirm_id"
              placeholder="请选择"
              class="ele-fluid">
              <el-option
                v-for="(item,index) in all_selectList"
                :key="index"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="选择客户:" v-if="form.firm_id != ''">
            <el-select
              filterable
              clearable
              v-model="form.client_id"
              placeholder="请选择"
              class="ele-fluid">
              <el-option
                v-for="(item,index) in all_client_selectList"
                :key="index"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="状态" label-width="100px">
            <el-select
              clearable
              v-model="form.status"
              placeholder="请选择"
              class="ele-fluid">
              <el-option label="启用" :value="0"/>
              <el-option label="禁用" :value="1"/>
            </el-select>
          </el-form-item>
          <el-form-item label="版本" label-width="100px">
            <el-select
              clearable
              v-model="form.version"
              placeholder="请选择"
              class="ele-fluid">
              <el-option label="V1" value="v1"/>
              <el-option label="V2" value="v2"/>
              <el-option label="V3" value="v3"/>
              <el-option label="V4" value="v4"/>
              <el-option label="V5" value="v5"/>
            </el-select>
          </el-form-item>
          <el-form-item label="测试账号" prop="test_ak_account">
            <el-input clearable style="" v-model="form.test_ak_account" placeholder="请输入测试访问账号"></el-input>
          </el-form-item>
          <el-form-item label="测试秘钥" prop="test_ak_secret">
            <el-input clearable style="" v-model="form.test_ak_secret" placeholder="请输入测试访问秘钥"></el-input>
          </el-form-item>
          <el-form-item label="测试地址" prop="test_api_url">
            <el-input clearable style="" v-model="form.test_api_url" placeholder="请输入测试地址"></el-input>
          </el-form-item>
          <el-form-item label="正式账号" prop="formal_ak_account">
            <el-input clearable style="" v-model="form.formal_ak_account" placeholder="请输入正式访问账号"></el-input>
          </el-form-item>
          <el-form-item label="正式秘钥" prop="formal_ak_secret">
            <el-input clearable style="" v-model="form.formal_ak_secret" placeholder="请输入正式访问秘钥"></el-input>
          </el-form-item>
          <el-form-item label="正式地址" prop="formal_api_url">
            <el-input clearable style="" v-model="form.formal_api_url" placeholder="请输入正式地址"></el-input>
          </el-form-item>
        </el-form>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="quxiao_addcity">取 消</el-button>
        <el-button type="primary" @click="addcity">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
// 权限
import {ShowTable} from '@/utils'

// 引入的接口
import {
  get_postbacklist,
  add_postbacksave,
  edit_postbacksave,
  get_postback_client
} from '@/api/system';
import {all_select} from "@/api/yunli";


export default {
  components:{

  },
  data(){
    return{
      // 表格搜索条件
      where: {
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      },
      // 表格数据
      List: [],
      // 总条目数
      total: 0,
      //加载动画
      loading:true,
      // 表头数据
      columns: [
        {
          type: "selection",
          isShow: true
        },
        {
          label: "Appid",
          prop: "appid",
          isShow: true
        },
        {
          label: "服务商名称",
          prop: "firm_name",
          isShow: true
        },
        {
          label: "客户名称",
          prop: "client_name",
          isShow: true
        },
        {
          label: "保司",
          prop: "insurer_name",
          isShow: true
        },
        {
          label: "账号",
          prop: "ak_account",
          slot:'ak_account',
          isShow: true
        },
        {
          label: "密钥",
          prop: "ak_secret",
          slot:'ak_secret',
          isShow: true
        },
        {
          label: "地址",
          prop: "api_url",
          slot:'api_url',
          isShow: true
        },
        {
          label: "版本",
          prop: "version",
          isShow: true
        },
        {
          label: "状态",
          prop: "status",
          slot:'status',
          isShow: true
        },
        {
          label: "操作",
          width: "200",
          fixed:'right',
          slot: "operationSlot",
          isShow: true
        },
      ],
      form: {
        firm_id:'',
        client_id:'',
        status: "",
        formal_ak_account:"",
        formal_ak_secret:"",
        formal_api_url:"",
        test_ak_account:"",
        test_ak_secret:"",
        test_api_url:"",
        version:"",
      },

      // 所有服务商
      all_selectList:[],
      //获取客户
      all_client_selectList:[],

      //新增
      dialogVisible: false,
      current:null,

      //弹窗标题
      title:'',
      //弹窗是否显示
      isUpdate:false,

      loading1:false,

    }
  },

  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
    this.getList();
    // 调用获取所有服务商
    this.getall_select();
  },

  methods:{
    /* 重置搜索 */
    reset() {
      this.where = {
        page: 1,
        limit: 10
      }
      this.getList();
    },
    // 获取全部服务商
    getall_select(){
      this.loading1 = true;
      all_select().then(res => {
        this.all_selectList = res.data;
        this.loading1 = false;
      })
    },

    //获取客户
    selectfirm_id(value){
      if(value != ''){
        this.getclient(value);
      }
    },

    // 获取客户
    getclient(vakye){
      get_postback_client(vakye).then(res => {
        this.all_client_selectList = res.data;
      })
    },
    // 获取列表
    getList(){
      this.loading = true;
      get_postbacklist(this.where).then(async (res) => {
        this.total = res.data.count;
        this.List = res.data.list;
        this.loading = false;
      }).catch((res) => {
        this.$Message.error(res.msg);
        this.loading = false;
      })
    },
    //新增
    addcity() {
      // if(this.form.firm_id == "" || this.form.client_id == "" || this.form.status == ""){
      //   this.$message.error("请填写完整信息");
      //   return false;
      // }
      console.log(this.form.firm_id)
      console.log(this.form.client_id)
      console.log(this.form.status)
      if(this.isUpdate == false){
        add_postbacksave(this.form).then((res) => {
          if (res.code == 200) {
            this.$message.success(res.msg);
            this.dialogVisible = false;
            this.getList();
            this.form = {
              firm_id: "",
              client_id: "",
              status: "",
              formal_ak_account:"",
              formal_ak_secret:"",
              formal_api_url:"",
              test_ak_account:"",
              test_ak_secret:"",
              test_api_url:"",
              version:"",
            };
          } else {
            this.$message.error(res.msg);
          }
        });
      }else {
        let data = {
          firm_id: this.current.firm_id,
          client_id: this.current.client_id,
          status: this.current.status,
          formal_ak_account:this.current.formal_ak_account,
          formal_ak_secret:this.current.formal_ak_secret,
          formal_api_url:this.current.formal_api_url,
          test_ak_account:this.current.test_ak_account,
          test_ak_secret:this.current.test_ak_secret,
          test_api_url:this.current.test_api_url,
          version:this.current.version,
        }
        edit_postbacksave(this.current.id,data).then((res) => {
          if (res.code == 200) {
            this.$message.success(res.msg);
            this.dialogVisible = false;
            this.getList();
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },

    //取消
    quxiao_addcity(){
      this.dialogVisible = false;
      this.form = {
        firm_id: "",
        client_id: "",
        status: "",
        formal_ak_account:"",
        formal_ak_secret:"",
        formal_api_url:"",
        test_ak_account:"",
        test_ak_secret:"",
        test_api_url:"",
        version:"",
      };
    },

    // 查询条件
    QueryList(){
      this.where.page = 1;
      this.where.limit = 10;
      this.getList();
    },

    //新增编辑
    addsheng(row) {
      if(row != null){
        this.title = '编辑客户回传配置'
        this.current = row;
        this.form = this.current;
        this.getclient(this.form.client_id)
        this.isUpdate = true;
      }else {
        this.title = '新增客户回传配置';
        this.form = {
          firm_id: "",
          client_id: "",
          status: "",
          formal_ak_account:"",
          formal_ak_secret:"",
          formal_api_url:"",
          test_ak_account:"",
          test_ak_secret:"",
          test_api_url:"",
          version:"",
        };
        this.isUpdate = false;
      }
      this.dialogVisible=true;
    },

    // 表头行的 className 的回调方法
    headerRowClassName({ row, rowIndex }) {
      return "header_row_class_name"
    },
    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #FAFAFA"
    },
    // 行的 className 的回调方法
    rowClassName({ row, rowIndex }) {
      return "row_class_name"
    },


  }

}
</script>

<style lang="scss" scoped>

</style>
